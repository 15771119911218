"use client"

import { useTheme } from "ui/styles"
import type { ReactNode } from "react"

import { Paper } from "ui/surfaces/Paper"

import { Stack } from "ui/layout/Stack"

import { LOGO_SIZE_FACTOR, LogoIcon, LogoText } from "@/app/_components/Logo"
import { Box } from "ui/layout/Box"
import HideServiceDeskWidget from "@/utils/serviceDesk/HideServiceDeskWidget"
import dynamic from "next/dynamic"

const SiteFooter = dynamic(() =>
  import("@/app/_components/SiteFooter").then((mod) => mod.SiteFooter),
)

export default function AuthLayout({ children }: { children: ReactNode }) {
  const theme = useTheme()

  return (
    <>
      <HideServiceDeskWidget />
      <main className="flex flex-col h-[100vh] w-full">
        <Box flexGrow={1} />
        <div className="flex justify-center h-full">
          <Paper
            variant="outlined"
            elevation={0}
            sx={{
              width: "480px",
              padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
              borderRadius: `${theme.shape.borderRadius * 2}px`,
              bgcolor: theme.palette.background.default,
            }}
            className="flex flex-col justify-center self-center"
          >
            <Stack spacing={theme.spacing(2.5)} className="h-full">
              <Stack
                className="self-center"
                spacing={theme.spacing(1)}
                direction="row"
                alignItems="center"
              >
                <LogoIcon height={theme.spacing(3 * LOGO_SIZE_FACTOR)} />
                <LogoText height={theme.spacing(3)} />
              </Stack>
              {children}
            </Stack>
          </Paper>
        </div>
        <SiteFooter />
      </main>
    </>
  )
}
