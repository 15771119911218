export default function HideServiceDeskWidget() {
  return (
    <style
      // biome-ignore lint/security/noDangerouslySetInnerHtml: This is a style tag that is used to hide the JSD widget
      dangerouslySetInnerHTML={{
        __html: `
          iframe[name="JSD widget"] {
              display: none !important;
          }
        `,
      }}
    />
  )
}
