import { styled, useTheme } from "ui/styles"
import { useEffect, useState } from "react"

export const testPrefix = "LogoIcon"
export interface LogoProps {
  height?: string
  color?: string
}

export const StyledSvg = styled("svg")(({ theme }) => ({
  transition: theme.transitions.create(["width", "height"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}))

export const LOGO_SIZE_FACTOR = 1.25

export function LogoIcon({ height = "24px", color }: LogoProps) {
  const theme = useTheme()
  const [useHeight, setUseHeight] = useState(height)

  useEffect(() => setUseHeight(height), [height])

  return (
    <StyledSvg
      height={useHeight}
      viewBox="0 0 125 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.149 62.0744C124.149 96.3572 96.3572 124.149 62.0744 124.149C27.7917 124.149 0 96.3572 0 62.0744C0 27.7917 27.7917 0 62.0744 0C96.3572 0 124.149 27.7917 124.149 62.0744ZM15.0865 68.4871C15.4317 68.7588 15.7732 69.0285 16.1118 69.2958C24.43 75.864 30.965 81.024 45.5583 79.5273C56.9702 78.3568 63.2401 73.3299 70.0154 67.8976C72.4935 65.9108 75.0391 63.8698 77.9287 61.9434C87.0781 55.8438 98.697 52.9049 108.453 52.1522C103.891 30.7273 84.8595 14.6566 62.0744 14.6566C35.8863 14.6566 14.6566 35.8863 14.6566 62.0744C14.6566 64.2493 14.803 66.39 15.0865 68.4871Z"
        fill={color ?? theme.palette.primary.main}
      />
    </StyledSvg>
  )
}

export function LogoText({ height = "13px", color }: LogoProps) {
  const theme = useTheme()
  const [useHeight, setUseHeight] = useState(height)
  useEffect(() => {
    setUseHeight(height)
  }, [height])

  return (
    <StyledSvg
      height={useHeight}
      viewBox="0 0 432 98"
      fill="none"
      xmlns="http://www.w3.org/2000/StyledSvg"
    >
      <path
        d="M383.708 73.6506H370.652L354.62 25.6506H367.58L377.228 54.4026L386.78 25.6506H399.74L409.292 54.4026L418.892 25.6506H431.9L415.82 73.6506H402.812L393.26 44.8506L383.708 73.6506Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M323.758 74.8986C319.342 74.8986 315.31 73.7626 311.662 71.4906C308.046 69.2186 305.15 66.1786 302.974 62.3706C300.83 58.5306 299.758 54.2906 299.758 49.6506C299.758 46.1306 300.382 42.8506 301.63 39.8106C302.878 36.7386 304.59 34.0506 306.766 31.7466C308.974 29.4106 311.534 27.5866 314.446 26.2746C317.358 24.9626 320.462 24.3066 323.758 24.3066C328.174 24.3066 332.19 25.4426 335.806 27.7146C339.454 29.9866 342.35 33.0426 344.494 36.8826C346.67 40.7226 347.758 44.9786 347.758 49.6506C347.758 53.1386 347.134 56.4026 345.886 59.4426C344.638 62.4826 342.91 65.1706 340.702 67.5066C338.526 69.8106 335.982 71.6186 333.07 72.9306C330.19 74.2426 327.086 74.8986 323.758 74.8986ZM323.758 61.6506C325.806 61.6506 327.646 61.1066 329.278 60.0186C330.91 58.8986 332.19 57.4266 333.118 55.6026C334.046 53.7786 334.51 51.7946 334.51 49.6506C334.51 47.4426 334.014 45.4266 333.022 43.6026C332.062 41.7466 330.766 40.2746 329.134 39.1866C327.502 38.0986 325.71 37.5546 323.758 37.5546C321.742 37.5546 319.918 38.1146 318.286 39.2346C316.654 40.3546 315.358 41.8266 314.398 43.6506C313.47 45.4746 313.006 47.4746 313.006 49.6506C313.006 51.9226 313.486 53.9706 314.446 55.7946C315.438 57.5866 316.75 59.0106 318.382 60.0666C320.014 61.1226 321.806 61.6506 323.758 61.6506Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M274.025 73.6506V1.65063H287.273V73.6506H274.025Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M247.538 16.3385V25.6505H260.258V38.8985H247.538V73.6505H234.29V38.8985H229.49V25.6505H234.29V16.3385C234.29 13.4265 235.01 10.7865 236.45 8.41853C237.89 6.01854 239.81 4.09854 242.21 2.65853C244.642 1.21853 247.314 0.498535 250.226 0.498535C252.37 0.498535 254.482 0.946535 256.562 1.84254C258.642 2.70653 260.498 4.06653 262.13 5.92254L252.626 15.2825C252.402 14.7705 252.066 14.3865 251.618 14.1305C251.17 13.8745 250.706 13.7465 250.226 13.7465C249.49 13.7465 248.85 14.0025 248.306 14.5145C247.794 14.9945 247.538 15.6025 247.538 16.3385Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M219.381 38.8986H210.741V73.6506H197.493V38.8986H190.869V25.6506H197.493V10.5786H210.741V25.6506H219.381V38.8986Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M178.873 43.1226V73.6506H165.625V46.7706C165.625 45.0746 165.209 43.5546 164.377 42.2106C163.577 40.8346 162.489 39.7466 161.113 38.9466C159.769 38.1466 158.265 37.7466 156.601 37.7466C154.937 37.7466 153.417 38.1466 152.041 38.9466C150.665 39.7466 149.577 40.8346 148.777 42.2106C147.977 43.5546 147.577 45.0746 147.577 46.7706V73.6506H134.329L134.281 1.65063H147.529L147.577 29.2026C149.177 27.6666 151.049 26.4666 153.193 25.6026C155.369 24.7386 157.657 24.3066 160.057 24.3066C163.545 24.3066 166.697 25.1546 169.513 26.8506C172.361 28.5466 174.633 30.8186 176.329 33.6666C178.025 36.4826 178.873 39.6346 178.873 43.1226Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M104.705 25.6506H117.953V74.3226C117.953 77.8746 117.233 81.0746 115.793 83.9226C114.353 86.8026 112.385 89.2666 109.889 91.3146C107.425 93.3626 104.577 94.9306 101.345 96.0186C98.1446 97.1066 94.7526 97.6506 91.1686 97.6506C87.7766 97.6506 84.5286 96.9786 81.4246 95.6346C78.3206 94.3226 75.5686 92.4986 73.1686 90.1626C70.7686 87.8266 68.8966 85.1386 67.5526 82.0986L79.6486 76.5786C80.3206 78.1786 81.2326 79.5546 82.3846 80.7066C83.5686 81.8586 84.9126 82.7386 86.4166 83.3466C87.9526 83.9866 89.5366 84.3066 91.1686 84.3066C92.9286 84.3066 94.6086 84.0986 96.2086 83.6826C97.8406 83.2666 99.2966 82.6426 100.577 81.8106C101.857 80.9786 102.865 79.9386 103.601 78.6906C104.337 77.4426 104.705 75.9866 104.705 74.3226V68.7546C103.233 70.5786 101.441 72.0666 99.3286 73.2186C97.2486 74.3386 94.8326 74.8986 92.0806 74.8986C88.6246 74.8986 85.3766 74.2426 82.3366 72.9306C79.3286 71.6186 76.6726 69.8106 74.3686 67.5066C72.0646 65.1706 70.2566 62.4826 68.9446 59.4426C67.6326 56.4026 66.9766 53.1386 66.9766 49.6506C66.9766 46.1626 67.6326 42.8986 68.9446 39.8586C70.2566 36.8186 72.0646 34.1466 74.3686 31.8426C76.6726 29.5066 79.3286 27.6826 82.3366 26.3706C85.3766 25.0586 88.6246 24.4026 92.0806 24.4026C94.8326 24.4026 97.2486 24.9786 99.3286 26.1306C101.441 27.2506 103.233 28.7226 104.705 30.5466V25.6506ZM92.2726 62.1786C94.4486 62.1786 96.4166 61.6186 98.1766 60.4986C99.9366 59.3466 101.313 57.8266 102.305 55.9386C103.329 54.0506 103.841 51.9546 103.841 49.6506C103.841 47.3466 103.329 45.2506 102.305 43.3626C101.313 41.4426 99.9366 39.9226 98.1766 38.8026C96.4166 37.6826 94.4486 37.1226 92.2726 37.1226C90.1286 37.1226 88.1606 37.6826 86.3686 38.8026C84.6086 39.9226 83.2006 41.4426 82.1446 43.3626C81.0886 45.2506 80.5606 47.3466 80.5606 49.6506C80.5606 51.9546 81.0886 54.0666 82.1446 55.9866C83.2006 57.8746 84.6086 59.3786 86.3686 60.4986C88.1606 61.6186 90.1286 62.1786 92.2726 62.1786Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M41.6189 25.6506H54.8669V73.6506H41.6189V25.6506ZM48.3389 20.1306C46.4189 20.1306 44.8029 19.4906 43.4909 18.2106C42.1789 16.9306 41.5229 15.3466 41.5229 13.4586C41.5229 11.5706 42.1789 9.98662 43.4909 8.70662C44.8029 7.42662 46.4029 6.78662 48.2909 6.78662C50.1789 6.78662 51.7629 7.42662 53.0429 8.70662C54.3549 9.98662 55.0109 11.5706 55.0109 13.4586C55.0109 15.3466 54.3709 16.9306 53.091 18.2106C51.8429 19.4906 50.2589 20.1306 48.3389 20.1306Z"
        fill={color ?? theme.palette.text.primary}
      />
      <path
        d="M0.866848 73.6506L0.818848 25.6506H14.0668L14.1148 29.2026C15.7148 27.6666 17.5868 26.4666 19.7308 25.6026C21.9068 24.7386 24.1948 24.3066 26.5948 24.3066C28.3548 24.3066 30.1148 24.5626 31.8748 25.0746L26.6429 38.4666C25.4909 37.9866 24.3228 37.7466 23.1388 37.7466C21.4748 37.7466 19.9548 38.1466 18.5788 38.9466C17.2028 39.7466 16.1148 40.8346 15.3148 42.2106C14.5148 43.5546 14.1148 45.0746 14.1148 46.7706V73.6506H0.866848Z"
        fill={color ?? theme.palette.text.primary}
      />
    </StyledSvg>
  )
}
